<template>
  <!-- 用户列表 -->
  <div class="padding24" id="crm_list">
    <div v-show="$route.meta.isShow">
      <div class="top">
        <div class="left"></div>
        <div class="right">
          <a-input
            v-model="getData.userName"
            placeholder="请输入手机号码"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-input
            v-model="getData.nickName"
            placeholder="请输入用户昵称"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            icon="search"
            type="primary"
            :disabled="searchDis"
            @click="searchClick"
          >
            搜索
          </a-button>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <img
          v-viewer
          class="avatar"
          slot="imageUrl"
          slot-scope="imageUrl, record"
          :src="record.imageUrl"
        />
        <span slot="agencyFlag" slot-scope="agencyFlag, record">
          <span v-if="record.agencyFlag == 0">否</span>
          <span v-if="record.agencyFlag == 1">是</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a v-if="isShowDetail" class="mr20" @click="detailClick(record)">
            修改
          </a>
          <a v-if="isShowUserInfo" @click="rzMessageClick(record)">认证信息</a>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="getData.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="tableData.length > 0"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
      <!-- 认证信息弹框 -->
      <RZMessage
        v-if="rzVis"
        :visible="rzVis"
        :userNo="currentUserNo"
        @cancel="() => (rzVis = false)"
      />
    </div>

    <router-view />
  </div>
</template>

<script>
import { UserListApi } from "@/request/api/crm.js";
import MyPagination from "@/components/pagination/MyPagination";
import RZMessage from "./modules/RZMessage.vue";
import { codeFn } from "@/utils/tools";

export default {
  components: { MyPagination, RZMessage },
  created() {
    if (codeFn("/admin/app/user/upd")) {
      this.isShowDetail = true;
    }
    if (codeFn("/admin/app/user/userInfoAut")) {
      this.isShowUserInfo = true;
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.path == "/crm/crmList") {
          this.listFn();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      count: 0,
      getData: {
        pageNo: 1,
        pageSize: 20,
        userName: "",
        nickName: "",
      },
      columns: [
        {
          title: "用户昵称",
          dataIndex: "nickName",
          key: "nickName",
          width: "10%",
        },
        {
          title: "用户手机号码",
          dataIndex: "userName",
          key: "userName",
          width: "10%",
        },
        {
          title: "头像",
          dataIndex: "imageUrl",
          key: "imageUrl",
          width: "10%",
          scopedSlots: { customRender: "imageUrl" },
        },
        // {
        //   title: "代理用户",
        //   dataIndex: "agencyFlag",
        //   key: "agencyFlag",
        //   width: "5%",
        //   scopedSlots: { customRender: "agencyFlag" },
        // },
        {
          title: "存证总数",
          dataIndex: "totalCz",
          key: "totalCz",
          width: "10%",
        },
             {
          title: "已使用",
          dataIndex: "useCzQuantity",
          key: "useCzQuantity",
          width: "10%",
        },
                     {
          title: "剩余",
          dataIndex: "czQuantity",
          key: "czQuantity",
          width: "10%",
        },
        {
          title: "登记总数",
          dataIndex: "totalDj",
          key: "totalDj",
          width: "10%",
        },
                {
          title: "已使用",
          dataIndex: "useDjQuantity",
          key: "useDjQuantity",
          width: "10%",
        },
                             {
          title: "剩余",
          dataIndex: "djQuantity",
          key: "djQuantity",
          width: "10%",
        },
        // {
        //   title: "创建时间",
        //   dataIndex: "createTime",
        //   width: "10%",
        //   sorter: true,
        //   key: "createTime",
        //   sorter: (a, b) => {
        //     let aTime = new Date(a.createTime).getTime();
        //     let bTime = new Date(b.createTime).getTime();
        //     return aTime - bTime;
        //   },
        // },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      searchDis: false,
      tableLoading: false,
      isShowDetail: false,
      isShowUserInfo: false,
      rzVis: false,
    };
  },
  methods: {
    detailClick(record) {
      this.$router.push(`/crm/crmDetail/${record.id}`);
    },
    rzMessageClick(record) {
      this.currentUserNo = record.userNo;
      this.rzVis = true;
    },
    searchClick() {
      this.searchDis = true;
      setTimeout(() => {
        this.searchDis = false;
      }, 3000);
      this.getData.pageNo = 1;
      this.listFn();
    },
    listFn() {
      this.tableLoading = true;
      UserListApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          this.count = data.count;
          data.data.map((item, index) => {
            item.key = index;
          });
          this.tableData = data.data;
        }
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.listFn();
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#crm_list");
    },
  },
};
</script>

<style lang="less" scoped>
#crm_list {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .right {
    /deep/.ant-input-affix-wrapper {
      margin: 0 10px;
    }
  }
}
.avatar {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
/deep/.ant-table-wrapper {
  margin-top: 20px;
}
</style>
