<template>
  <!-- 认证信息弹框 -->
  <a-modal
    :visible="visible"
    title="认证信息"
    :maskClosable="false"
    :width="1300"
    @cancel="() => $emit('cancel')"
    @ok="() => $emit('cancel')"
  >
    <div class="top">
      <div class="left"></div>
      <div class="right">
        <a-select
          v-model="getData.autType"
          style="width: 120px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="autTypeChange"
        >
          <a-select-option v-for="item in autTypeArr" :key="item.key">
            {{ item.value }}
          </a-select-option>
        </a-select>
        <a-input
          v-model="getData.autName"
          placeholder="请输入证件名称"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-input
          v-model="getData.autNo"
          placeholder="请输入证件号码"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button icon="search" type="primary" @click="searchClick">
          搜索
        </a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
      :scroll="{ y: 500 }"
    >
      <span slot="autType" slot-scope="autType, record">
        <span v-if="record.autType == 1">个人</span>
        <span v-if="record.autType == 2">企业</span>
      </span>
      <img
        v-viewer
        class="avatar"
        slot="certificateImage"
        slot-scope="certificateImage, record"
        :src="record.certificateImage"
      />
    </a-table>
    <MyPagination
      :count="count"
      :pageNo="getData.pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="tableData.length > 0"
    />
  </a-modal>
</template>

<script>
import { UserInfoAutApi } from "@/request/api/crm";
import MyPagination from "@/components/pagination/MyPagination.vue";
export default {
  components: { MyPagination },
  created() {
    this.listFn();
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      getData: {
        pageNo: 1,
        pageSize: 20,
        userNo: this.userNo,
        autType: "",
        autName: "",
        autNo: "",
      },
      columns: [
        {
          title: "证件类型",
          dataIndex: "autType",
          key: "autType",
          width: "20%",
          scopedSlots: { customRender: "autType" },
        },
        {
          title: "证件名称",
          dataIndex: "certificateName",
          key: "certificateName",
          width: "20%",
        },
        {
          title: "证件图片",
          dataIndex: "certificateImage",
          key: "certificateImage",
          width: "20%",
          scopedSlots: { customRender: "certificateImage" },
        },
        {
          title: "证件号码",
          dataIndex: "certificateNumber",
          key: "certificateNumber",
          width: "20%",
        },
      ],
      tableData: [],
      count: 0,
      autTypeArr: [
        { key: "", value: "全部类型" },
        { key: 1, value: "个人" },
        { key: 2, value: "企业" },
      ],
      tableLoading: false,
    };
  },
  methods: {
    autTypeChange(value) {
      this.getData.autType = value;
      this.listFn();
    },
    searchClick() {
      this.getData.pageNo = 1;
      this.listFn();
    },
    listFn() {
      this.tableLoading = true;
      UserInfoAutApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.map((item, index) => {
            item.key = index;
          });
          this.count = data.count;
          this.tableData = data.data;
        } else {
          this.tableLoading = false;
        }
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.listFn();
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .right {
    /deep/.ant-input-affix-wrapper {
      margin: 0 10px;
    }
  }
}
.avatar {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
</style>